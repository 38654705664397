import React, { useEffect, useState, useContext } from "react";
import { useTheme } from "@mui/material/styles";
import { Typography, useMediaQuery, Box, Button, Dialog, Avatar } from "@mui/material";
import FollowedCard from "ui-component/cards/FollowedCard";
import DiscoverFollowedCard from "ui-component/cards/DiscoverFollowedCard";
import ReactPlayer from "react-player";
import { useTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import _ from "lodash";
import { purchaseVideo, getVideoUrl, fetchAddReaction, fetchVideoRecommendations } from "api";
import { makeStyles } from '@mui/styles';
import { useNavigate } from "react-router-dom";
import SwipeableButton from "./SwipeableButton";
import { MyContext } from "context/CoinsContext";

const useStyles = makeStyles(theme => ({
  mainContainer: {
      [theme.breakpoints.down('md')]: {
          padding: '0'
      },
  },
  root: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      gap: '2em'
    },
    padding: '1em 0em 3em 0em',
  },
  box: {
    width: '30%',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    border: '1px solid #D3D3D3',
    padding: '1em',
    borderRadius: '16px',
    cursor: 'pointer',
  },

  coinsIcon: {
      width: 40,
      height: 40,
      filter: 'grayscale(100%)',
  },

  coinsContainer: {
      justifyContent: 'center',
      display: 'flex',
      width: '100%',
      padding: '2em 0em'
  },
  inputContainer: {
      borderRadius: '25px !important',
      textTransform: 'uppercase'
  },

  termsContainer: {
      justifyContent: 'center',
      display: 'flex',
      gap: '10px'
  },

  buyButton: {
      textAlign: 'center !important',
      textTransform: 'uppercase !important',
      padding: '1em 2em !important',
      borderRadius: '50px !important',
      fontWeight: 'bold !important'
  },
  defaultValueInput: {
      textTransform: 'uppercase'
  },

  myDialog: {
    position: 'inherit !important'
  },
  cancelButton: {
    textAlign: 'center !important',
    textTransform: 'uppercase !important',
    padding: '1em 3em !important',
    borderRadius: '50px !important',
    fontWeight: 'bold !important',
    backgroundColor: 'transparent',
    color: 'black !important',
    border: '0 !important',
  }
}));


export const DisoverVideoView = React.memo(
  ({ item, isUrl, idx, search, discover, fromFollowing, disableCard }) => {

    useEffect(() => {
      getVideoUrls();
    }, []);
    const classes = useStyles();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down("md"));
    const [show, setShow] = React.useState(false);
    const [status, setStatus] = React.useState("");
    const [purchaseUrl, setPurchaseUrl] = useState("");
    const user = useSelector((state) => state.auth.user);
    const { coins, updateCoins } = useContext(MyContext);
    const me = useSelector((state) => state.auth.profile);
    const actor = _.get(item, "actor");
    const [isPurchased, setIsPurchased] = React.useState(!_.isNil(_.get(item, "own_reactions.purchase")) ||
    _.get(me, "username") === _.get(actor, "id"));
    const [loadingPurchase, setLoadingPurchase] = React.useState(false);
    const isFree = item.free;

    const getVideoUrls = async () => {
      try {
        if (isPurchased || isFree) {
          const video = await getVideoUrl(item.id);
          setPurchaseUrl(video?.url);
        }
      } catch (e) {}
    };

    var publisherData;
    if (isUrl) {
      publisherData = item.actor.data;
      publisherData["username"] = item.actor.id;
    }

    const buy = async () => {
      setLoadingPurchase(true);
      try {
        const purchase = await purchaseVideo(item.id);
        setStatus(purchase.success ? "sucess" : status);
        try{
          const fetch = await fetchAddReaction(item.streamActivityId, 'purchase');
          const video = await getVideoUrl(item.id);
          if(video) {
            setPurchaseUrl(video?.url);
            setIsPurchased(true)
            updateCoins(user.username)
            console.log(fetch)
            fetchVideoRecommendations(item.id)
          }
        } catch {}

      } catch (e) {
        setShow(true);
        setStatus(e.response.data.message)
      }finally {
        setLoadingPurchase(false);
      }
    }

    return (
      // <Grid item xs={search ? 2 : 0} sm={search ? 4 : 0} md={search ? 4 : 0} lg={search ? 4 : 0}>
      <>
        <div
          style={{
            width: matchDownSM ? "100%" : search ? "96%" : "80%",
            display: "flex",
            flexDirection: "column",
            margin: "auto",
          }}
        >
          {discover ? (
            <DiscoverFollowedCard
              discover={discover}
              search={search}
              sx={{ width: 50, height: 50 }}
              index={idx}
              item={isUrl ? publisherData : item.owner}
            />
          ) : (
            <>
              {!disableCard && (
                <FollowedCard
                  allData={item}
                  fromFollowing={fromFollowing}
                  search={search}
                  sx={{ width: 50, height: 50 }}
                  index={idx}
                  item={isUrl ? publisherData : item.owner}
                />
              )}
            </>
          )}

          <div
            style={{
              background: "black",
              borderRadius: matchDownSM || search ? 0 : 30,
              //  padding: matchDownSM ? 0 : 20,
              alignItems: "center",
              justifyContent: "center",
              display: "flex",
              position: "relative"
            }}
          >
            <ReactPlayer
              url={
                isUrl
                  ? isPurchased
                    ? purchaseUrl
                    : item?.trailer
                  : isPurchased
                  ? purchaseUrl
                  : item?.trailer
              }
              // playing
              config={{
                youtube: {
                  playerVars: { showinfo: 1 },
                },
                facebook: {
                  appId: "12345",
                },
              }}
              height={
                matchDownSM && !search ? "400px" : search ? "400px" : "600px"
              }
              width="100%"
              controls
            />
            {isPurchased || isFree ? null : (
              <SwipeableButton click={buy} loadingPurchase={loadingPurchase} />
            )}

          </div>

          <Typography
            color={"black"}
            gutterBottom
            sx={{ margin: 2 }}
            variant={"h4"}
          >
            {item.title}
          </Typography>


          <Dialog open={show} onClose={()=> setShow(false)} classes={{ paper: classes.myDialog }}>
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            background: "#fff",
            p: 5,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            width: '60%',
            [theme.breakpoints.down("md")]: {
              width: '100%',
            },
          }}
        >
          {status === "Already purchased" ? null : (
            <>
            <Typography variant="h3" mb={3} align="center" color="black">
          {t("purchaseModal.availableVideosLabel")}
          </Typography>
          <Avatar
            variant="rounded"

            sx={{
              alignItems: "center",
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumsAvatar,
              transition: "all .2s ease-in-out",
              background: "transparent",
              color: theme.palette.secondary.dark,
              marginTop: '1em',
              marginBottom: '1em',
              width: '100%',
            }}
            aria-haspopup="true"
            color="inherit"
          >
          <img
              style={{ width: 30, height: 30, marginRight: 5 }}
              src={require("./../../../Assets/images/ic_play_coins.png")}
              alt="coins"
            />
            <Typography
              sx={{
                color: theme.palette.secondary.dark,

                marginX: "2px",
                mt: "2px",
              }}
              variant="h4"
            >
              {coins}
            </Typography>
          </Avatar>
            </>
          )}

          <Typography variant="h2" align="center" mt={2} fontWeight="bold">
            {status === "Already purchased" ? "Video already purchased" : t("purchaseModal.purchaseLabel")}
          </Typography>
          <Box display="flex" flexDirection="column" alignItems="center" mt={4} >
            {status === "Already purchased" ? null : (
              <Button className={classes.buyButton} variant="contained" color="primary" mb={1} onClick={() => navigate('/products')}>
              {t("purchaseModal.purchaseInAppButtonLabel")}
              </Button>
            )}

            <Button className={classes.cancelButton} onClick={()=> setShow(false)} variant="outlined" color="primary">
              {t("purchaseModal.back")}
            </Button>
          </Box>
        </Box>
      </Dialog>
        </div>
      </>
    );
  }
);
